import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PageTitle from '../PageTitle';

import Alert from '../Alert';

import '../../styles/AccountsPage.scss';
import '../../styles/Button.scss';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const { resetError } = this.props;
    resetError();
  }

  handleLoginClick(e) {
    e.preventDefault();
    const { logIn } = this.props;
    const { email, password } = this.state;
    logIn(email, password);
  }

  render() {
    const { isLoggingIn, error } = this.props;
    const { email, password } = this.state;

    return (
      <PageTitle title="Login">
        <form noValidate autoComplete="off">
          <h1 className="AccountsPage-Title">Online Courses</h1>
          <p className="AccountsPage-Description">
            {
              "Welcome! We're glad you're here. Enter your email and password to access your courses."
            }
          </p>
          {error && <Alert message={error} level="error" />}
          <input
            placeholder="Email"
            name="email"
            type="email"
            value={email}
            aria-label="Email"
            disabled={isLoggingIn}
            onChange={e => this.setState({ email: e.target.value })}
          />
          <input
            placeholder="Password"
            name="password"
            type="password"
            aria-label="Password"
            value={password}
            disabled={isLoggingIn}
            onChange={e => this.setState({ password: e.target.value })}
          />
          <button
            className="Button"
            disabled={isLoggingIn}
            onClick={this.handleLoginClick.bind(this)}
            type="submit"
          >
            Login
          </button>

          <Link to="/login/forgot">Forgot Your Password?</Link>
        </form>
      </PageTitle>
    );
  }
}

LoginForm.propTypes = {
  logIn: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  isLoggingIn: PropTypes.bool,
  error: PropTypes.string,
};

LoginForm.defaultProps = {
  isLoggingIn: false,
  error: '',
};

export default LoginForm;
