import qs from 'qs';
import { connect } from 'react-redux';
import { resetPassword, validateResetCredentials } from '../actions/auth';
import ResetPassword from '../components/Login/ResetPassword';

export const mapStateToProps = ({ auth }) => ({
  isLoggedIn: !!auth.user && auth.user.challengeName !== 'NEW_PASSWORD_REQUIRED',
  isResetting: auth.isFetching,
  isValidating: auth.isValidating,
  resetCredentials: auth.resetCredentials,
  error: auth.error,
});

export const mapDispatchToProps = (dispatch, { location }) => ({
  submitForgotPassword({ newPassword, confirmPassword }) {
    return dispatch(resetPassword(newPassword, confirmPassword));
  },
  validateCode() {
    const { email, code } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return dispatch(validateResetCredentials(email, code));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
