import {
  REQUEST_USER,
  RESTORE_USER,
  STORE_USER,
  STORE_RESET_CREDS,
  DELETE_USER,
  LOGIN_ERROR,
} from '../actions/auth';

const initialState = {
  isFetching: false,
  isRestoring: false,
  isValidating: true,
  user: null,
  error: null,
  resetCredentials: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case REQUEST_USER:
      return Object.assign({}, state, { isFetching: true, error: null });
    case RESTORE_USER:
      return Object.assign({}, state, { isRestoring: true });
    case STORE_USER:
      return Object.assign({}, state, {
        isRestoring: false,
        isFetching: false,
        user: action.user,
        error: null,
        resetCredentials: null,
      });
    case STORE_RESET_CREDS:
      return Object.assign({}, state, {
        isValidating: false,
        resetCredentials: action.resetCredentials,
      });
    case DELETE_USER:
      return Object.assign({}, state, { isRestoring: false, isFetching: false, user: null });
    case LOGIN_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        isRestoring: false,
        error: action.error,
      });
    default:
      return state;
  }
}
