import Auth from '@aws-amplify/auth';
import fetch from '../lib/api';

export const REQUEST_COURSE_LIST = 'REQUEST_COURSE_LIST';
export function requestCourseList() {
  return {
    type: REQUEST_COURSE_LIST,
  };
}

export const STORE_COURSE_LIST = 'STORE_COURSE_LIST';
export function storeCourseList(courses) {
  return {
    type: STORE_COURSE_LIST,
    courses,
    receivedAt: Date.now(),
  };
}

function fetchCourseList() {
  return dispatch => {
    dispatch(requestCourseList());

    return Auth.currentSession()
      .then(({ idToken }) => {
        const { jwtToken } = idToken;

        return fetch('/courses', { token: jwtToken });
      })
      .then(courses => dispatch(storeCourseList(courses)));
  };
}

function shouldFetchCourseList({ courses }) {
  const { receivedAt } = courses;
  const twoHours = 7200000;
  return Object.keys(courses).length === 0 || !receivedAt || receivedAt + twoHours < Date.now();
}

export function fetchCourseListIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchCourseList(getState())) {
      return dispatch(fetchCourseList());
    }

    return Promise.resolve();
  };
}
