import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';

const SITE_TITLE = ['Vanessa Marin Sex Therapy'];

const PageTitle = ({ title, ...props }) => {
  const fullTitle = (title ? [title] : []).concat(SITE_TITLE).join(' | ');

  return <DocumentTitle title={fullTitle} {...props} />;
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

PageTitle.defaultProps = {
  title: '',
};

export default PageTitle;
