import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

// TODO: Share this logic amongst other containers?
export const mapStateToProps = ({ auth }) => ({
  isLoggedIn: !!auth.user && auth.user.challengeName !== 'NEW_PASSWORD_REQUIRED',
});

export const PrivateRouteComponent = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }, // eslint-disable-line react/prop-types
          }}
        />
      )
    }
  />
);

PrivateRouteComponent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  isLoggedIn: PropTypes.bool,
};

PrivateRouteComponent.defaultProps = {
  isLoggedIn: false,
};

export default connect(mapStateToProps)(PrivateRouteComponent);
