import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import classNames from 'classnames';
import { ChevronLeft } from 'react-feather';

import Layout from '../Layout';
import Footer from './Footer';
import Nav from './Nav';
import Content from '../../containers/Page';
import UrlResolver from '../../lib/UrlResolver';

import '../../styles/Footer.scss';

class Course extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageMasterList: [],
    };
  }

  componentDidMount() {
    this.fetchOutline();
  }

  componentDidUpdate({ courseId: previousCourseId, match: previousMatch }) {
    const { courseId, match } = this.props;
    if (courseId !== previousCourseId) {
      this.fetchOutline();
    }

    if (previousMatch.params.pageId !== match.params.pageId) {
      // this.setState({ bodyScroll: 0 });
      // this.toggleNav(false);
    }
  }

  fetchOutline() {
    const { fetchCourseOutline, history } = this.props;
    return fetchCourseOutline()
      .then(this.collectMasterList.bind(this))
      .catch(error => {
        if (error.message === 'COURSE_NOT_FOUND') {
          history.replace('/courses');
        }
      });
  }

  collectMasterList() {
    const { outline, keys } = this.props;
    if (outline.length) {
      this.setState({
        pageMasterList: outline[0].groups.reduce(
          (pages, group) => (keys[group.dir] ? pages.concat(group.id, group.pages) : pages),
          [],
        ),
      });
    }
  }

  addLinkTo(page) {
    if (page) {
      const { courseId } = this.props;
      return { ...page, link: `/courses/${courseId}/${page.id}` };
    }
    return page;
  }

  prevNextLinks(pageId) {
    const { pages } = this.props;
    const { pageMasterList } = this.state;
    const pageNumber = pageMasterList.indexOf(pageId);

    if (pageNumber < 0) {
      return [null, null];
    }

    const prevPage = pages[pageMasterList[pageNumber - 1]];
    const nextPage = pages[pageMasterList[pageNumber + 1]];

    return [this.addLinkTo(prevPage), this.addLinkTo(nextPage)];
  }

  render() {
    const {
      courseId,
      outline,
      pages,
      match,
      fetchPageContent,
      title,
      subtitle,
      keys,
      status,
      theme,
      notAvailableMessage,
    } = this.props;
    const { pageId } = match.params;
    const page = pages[pageId];

    if (status === 'paused') {
      return <Redirect to="/courses" />;
    }

    // handle case when you try to view a page that's coming soon
    if (outline.length && (!pageId || !page)) {
      return <Redirect to={`/courses/${courseId}/${outline[0].groups[0].id}`} />;
    }

    if (!outline.length) {
      return <p>loading</p>;
    }

    const [prevPage, nextPage] = this.prevNextLinks(pageId);
    const pageTitle = [page.title, subtitle, title].filter(v => v).join(' | ');
    const urlResolver = new UrlResolver({ courseId, keys });

    return (
      <DocumentTitle title={pageTitle || title}>
        <Layout
          nav={layout => (
            <React.Fragment>
              <div className={classNames('CourseTitle', theme)}>
                <Link to="/courses" className="BackToCourses">
                  <ChevronLeft size={20} />
                  Back to My Courses
                </Link>
                <h1 style={{ maxWidth: '200px' }}>{title}</h1>
                <h2>{subtitle}</h2>
              </div>
              <Nav
                courseId={courseId}
                outline={outline}
                pages={pages}
                keys={keys}
                notAvailableMessage={notAvailableMessage}
                onLinkClick={layout.resetNav}
              />
            </React.Fragment>
          )}
          footer={() => <Footer prevPage={prevPage} nextPage={nextPage} />}
        >
          <Content
            pageId={pageId}
            page={page}
            fetchPageContent={fetchPageContent}
            urlResolver={urlResolver}
          />
        </Layout>
      </DocumentTitle>
    );
  }
}

Course.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  courseId: PropTypes.string.isRequired,
  fetchCourseOutline: PropTypes.func.isRequired,
  fetchPageContent: PropTypes.func.isRequired,
  outline: PropTypes.arrayOf(PropTypes.object),
  pages: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  title: PropTypes.string,
  status: PropTypes.string,
  subtitle: PropTypes.string,
  keys: PropTypes.shape({
    Host: PropTypes.string,
  }),
  theme: PropTypes.string,
  notAvailableMessage: PropTypes.string,
};

Course.defaultProps = {
  outline: [],
  pages: {},
  keys: {},
  title: '',
  subtitle: '',
  status: '',
  theme: '',
  notAvailableMessage: '',
};

export default Course;
