import qs from 'qs';

class UrlResolver {
  constructor({ courseId, keys }) {
    this.courseId = courseId;
    this.keys = keys || {};
  }

  absoluteUrlFor(file) {
    const kid = file.substring(0, file.lastIndexOf('/'));
    const path = `${this.courseId}/${file}`;
    const url = `${this.keys.Host || `${process.env.REACT_APP_PUBLIC_CDN}/`}${path}`;

    return this.keys && this.keys['Key-Pair-Id']
      ? `${url}?${qs.stringify({
          ...this.keys[kid],
          'Key-Pair-Id': this.keys['Key-Pair-Id'],
        })}`
      : url;
  }
}

export default UrlResolver;
