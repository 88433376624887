import React from 'react';
import PropTypes from 'prop-types';

const Audio = ({ src }) => (
  <audio controls key={src}>
    <source src={src} />
  </audio>
);

Audio.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Audio;
