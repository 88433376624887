import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ChevronLeft } from 'react-feather';

import ConditionalLink from '../ConditionalLink';

import '../../styles/Course.scss';
import '../../styles/CourseNav.scss';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 'auto',
      currentModule: null,
    };

    this.onModuleClick = this.onModuleClick.bind(this);
    this.showModule = this.showModule.bind(this);
    this.resetModule = this.resetModule.bind(this);
  }

  componentDidMount() {
    const { outline } = this.props;

    if (outline.length === 1) {
      const module = outline[0].dir;
      this.showModule(module);
    }
  }

  onModuleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.showModule(e.currentTarget.getAttribute('href'));
  }

  isAccessible({ dir }) {
    const { keys } = this.props;
    return !!keys[dir];
  }

  linkFor(pageId) {
    const { courseId } = this.props;
    return `/courses/${courseId}/${pageId}`;
  }

  showModule(module) {
    const moduleElement = document.getElementById(module);

    this.setState({
      currentModule: module,
      height: moduleElement.getBoundingClientRect().height,
    });

    setTimeout(() => {
      this.setState({
        height: moduleElement.getBoundingClientRect().height,
      });
    });
  }

  resetModule(e) {
    e.preventDefault();

    this.setState(
      {
        currentModule: null,
      },
      () => {
        setTimeout(() => {
          this.setState({
            height: 'auto',
          });
        }, 253);
      },
    );
  }

  render() {
    const { notAvailableMessage, outline, pages, onLinkClick } = this.props;
    const { height, currentModule } = this.state;

    return (
      <div className="CourseNavigation" style={{ height }}>
        <div style={{ marginTop: '2rem' }}>
          <h2 className="CourseModule-Header">Course Menu</h2>
          {outline.map(({ displayName, dir }) => (
            <button
              key={dir}
              className="CourseNav-Header"
              href={dir}
              onClick={this.onModuleClick}
              type="button"
            >
              {displayName}
            </button>
          ))}
        </div>
        {outline.map(module => (
          <CSSTransition
            in={currentModule === module.dir}
            timeout={253}
            classNames="slide"
            key={module.dir}
          >
            <div className="CourseModule" id={module.dir}>
              {outline.length > 1 && (
                <button
                  href="#"
                  className="BackToCourses"
                  onClick={this.resetModule}
                  type="button"
                  style={{ marginBottom: 0 }}
                >
                  <ChevronLeft size={20} />
                  Back to Course Menu
                </button>
              )}
              {module.displayName ? (
                <h2 className="CourseModule-Header">{module.displayName}</h2>
              ) : null}
              <ol className="CourseNav">
                {module.groups.map(group => (
                  <li className="CourseNav-Week" key={group.title}>
                    <ConditionalLink
                      className="CourseNav-Header"
                      to={this.linkFor(group.id || group.pages[0])}
                      type={group.id ? NavLink : Link}
                      accessible={this.isAccessible(group)}
                      onClick={onLinkClick}
                    >
                      {group.type && (
                        <p className="CourseNav-WeekNumber">{`${group.type} ${group.label}`}</p>
                      )}
                      <p className="CourseNav-WeekTitle">{group.title}</p>
                      {!this.isAccessible(group) && (
                        <span className="CourseNav-ComingSoon">
                          {notAvailableMessage || 'Coming Soon'}
                        </span>
                      )}
                    </ConditionalLink>

                    <ol className="CourseNav-Lessons">
                      {this.isAccessible(group)
                        ? group.pages.map(pageId => (
                            <li key={pageId}>
                              <NavLink to={this.linkFor(pageId)} onClick={onLinkClick}>
                                <span className="CourseNav-LessonNumber">
                                  {pages[pageId].label}
                                </span>
                                {pages[pageId].title}
                              </NavLink>
                            </li>
                          ))
                        : null}
                    </ol>
                  </li>
                ))}
              </ol>
            </div>
          </CSSTransition>
        ))}
      </div>
    );
  }
}

Navigation.propTypes = {
  courseId: PropTypes.string.isRequired,
  outline: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.shape({}).isRequired,
  keys: PropTypes.shape({
    Host: PropTypes.string,
  }).isRequired,
  onLinkClick: PropTypes.func,
  notAvailableMessage: PropTypes.string,
};

Navigation.defaultProps = {
  onLinkClick: () => {},
  notAvailableMessage: '',
};

export default Navigation;
