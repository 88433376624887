import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ConditionalLink from './ConditionalLink';

import '../styles/CourseItem.scss';

const CourseItem = ({ title, subtitle, courseId, logo, description, status, links, footer }) => {
  const launchLinks = links ? links : [{ path: `/courses/${courseId}`, text: 'Enter Course' }];

  const logoImage = (
    <figure className="CourseItem-Logo">
      <img className="CourseItem-Img" src={logo} alt={`${title}-${subtitle}-logo`} />
    </figure>
  );

  return (
    <section className="CourseItem">
      {logo && logoImage}
      <h1 className="CourseItem-Title">{title}</h1>
      {subtitle && <h2 className="CourseItem-Subtitle">{subtitle}</h2>}
      <div className="CourseItem-Body">
        <p className="CourseItem-Desc">{description}</p>
      </div>
      <div className="CourseItem-Links">
        {launchLinks.map(link => (
          <ConditionalLink
            key={link.path}
            type={Link}
            to={link.path}
            className="Button Button--flex Button--smaller"
            accessible={status !== 'paused'}
          >
            {link.text}
          </ConditionalLink>
        ))}
      </div>
      {footer && (
        <p className="CourseItem-Footer">
          <i>{footer}</i>
        </p>
      )}
    </section>
  );
};

CourseItem.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  subtitle: PropTypes.string,
  courseId: PropTypes.string.isRequired,
  description: PropTypes.string,
  logo: PropTypes.string,
};

CourseItem.defaultProps = {
  subtitle: '',
  description: '',
  logo: '',
  status: '',
};

export default CourseItem;
