import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PageTitle from '../PageTitle';

import Alert from '../Alert';

import '../../styles/AccountsPage.scss';
import '../../styles/Button.scss';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccessMessage: false,
      email: '',
    };
  }

  componentDidMount() {
    const { resetError } = this.props;
    resetError();
  }

  handleClick(e) {
    e.preventDefault();

    const { requestForgotCode } = this.props;
    const { email } = this.state;

    return requestForgotCode(email)
      .then(() => this.setState({ showSuccessMessage: true }))
      .catch(() => this.setState({ showSuccessMessage: false }));
  }

  render() {
    const { error, isSendingEmail } = this.props;
    const { email, showSuccessMessage } = this.state;

    return (
      <PageTitle title="Forgot Password">
        <form noValidate autoComplete="off">
          <h1 className="AccountsPage-Title">Forgot Password</h1>
          <p className="AccountsPage-Description">
            Forgot your password? No problem. Enter your email address and I&apos;ll send you
            directions for resetting your password.
          </p>
          {error && <Alert message={error} level="error" />}
          {showSuccessMessage && (
            <Alert message="Sent! Check your email for a reset link." level="success" />
          )}
          <input
            placeholder="Email"
            name="email"
            type="email"
            value={email}
            disabled={isSendingEmail}
            onChange={e => this.setState({ email: e.target.value })}
          />
          <button
            className="Button"
            type="submit"
            disabled={isSendingEmail}
            onClick={this.handleClick.bind(this)}
          >
            Send Reset Email
          </button>

          <Link to="/login">Go back to login page</Link>
        </form>
      </PageTitle>
    );
  }
}

ForgotPassword.propTypes = {
  requestForgotCode: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  error: PropTypes.string,
  isSendingEmail: PropTypes.bool,
};

ForgotPassword.defaultProps = {
  error: '',
  isSendingEmail: false,
};

export default ForgotPassword;
