import { combineReducers } from 'redux';
import auth from './auth';
import courses from './courses';
import pages from './pages';

export default combineReducers({
  auth,
  courses,
  pages,
});
