export default (path, { verb = 'GET', token, apiBase = process.env.REACT_APP_API_BASE } = {}) => {
  const headers = new Headers();

  if (token) {
    headers.append('Authorization', token);
  }

  const request = new Request(apiBase + path, {
    method: verb,
    mode: 'cors',
    headers,
  });

  return fetch(request)
    .then(response => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json().then(json => ({ content: json, response }));
      }
      return response.text().then(text => ({ content: text, response }));
    })
    .then(({ content, response }) => {
      if (!response.ok) {
        return Promise.reject(content);
      }

      return content;
    });
};
