import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PageTitle from './PageTitle';

import PrivateRoute from '../containers/PrivateRoute';
import Course from '../containers/Course';
import CourseIndex from '../containers/CourseIndex';
import Login from '../containers/Login';
import Logout from '../containers/Logout';

import '../styles/base.scss';

const App = () => (
  <PageTitle>
    <BrowserRouter>
      <Switch>
        <Route path="/courses/:courseId/:pageId*" component={Course} />
        <PrivateRoute path="/courses" component={CourseIndex} />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Redirect from="/" to="/courses" />
      </Switch>
    </BrowserRouter>
  </PageTitle>
);

export default App;
