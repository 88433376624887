import { connect } from 'react-redux';
import { setNewPassword } from '../actions/auth';
import CreatePassword from '../components/Login/CreatePassword';

export const mapStateToProps = ({ auth }) => ({
  disabled: auth.isFetching,
  error: auth.error,
});

export const mapDispatchToProps = dispatch => ({
  onSubmit({ newPassword, confirmPassword }) {
    return dispatch(setNewPassword(newPassword, confirmPassword));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePassword);
