import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'react-feather';

const CourseFooter = ({ prevPage, nextPage }) =>
  prevPage || nextPage ? (
    <div className="Footer">
      {prevPage ? (
        <Link to={prevPage.link} className="Footer-Item Footer-Item--left">
          <ArrowLeft className="Footer-Icon" size={20} />
          <div className="Footer-ItemBody">
            {prevPage.label && (
              <React.Fragment>
                <span className="Footer-LessonNumber">
                  {`${prevPage.type || ''} ${prevPage.label}`}
                </span>
                <br />
              </React.Fragment>
            )}
            <span className="Footer-LessonTitle">{prevPage.title}</span>
          </div>
        </Link>
      ) : (
        <div className="Footer-Item Footer-Item--left" />
      )}
      {nextPage ? (
        <Link to={nextPage.link} className="Footer-Item Footer-Item--right">
          <div className="Footer-ItemBody">
            {nextPage.label && (
              <React.Fragment>
                <span className="Footer-LessonNumber">
                  {`${nextPage.type || ''} ${nextPage.label}`}
                </span>
                <br />
              </React.Fragment>
            )}
            <span className="Footer-LessonTitle">{nextPage.title}</span>
          </div>
          <ArrowRight className="Footer-Icon" size={20} />
        </Link>
      ) : (
        <div className="Footer-Item Footer-Item--right" />
      )}
    </div>
  ) : null;

const pageType = PropTypes.shape({
  link: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.number,
  title: PropTypes.string,
});

CourseFooter.propTypes = {
  prevPage: pageType,
  nextPage: pageType,
};

CourseFooter.defaultProps = {
  prevPage: null,
  nextPage: null,
};

export default CourseFooter;
