import React from 'react';
import PropTypes from 'prop-types';
import UrlResolver from '../lib/UrlResolver';

import Audio from './Audio';
import Vimeo from './Vimeo';

const ImageFacade = ({ title, alt, src, urlResolver }) => {
  switch (alt) {
    case 'video':
      return <Vimeo src={src} />;
    case 'audio':
      return <Audio src={urlResolver.absoluteUrlFor(src)} />;
    default:
      return <img title={title} alt={alt} src={src} />;
  }
};

ImageFacade.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  urlResolver: PropTypes.instanceOf(UrlResolver),
};

ImageFacade.defaultProps = {
  title: '',
  src: '',
  urlResolver: () => {},
};

export default ImageFacade;
