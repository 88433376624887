import { connect } from 'react-redux';
import { fetchCourseListIfNeeded } from '../actions/courseList';
import CourseIndex from '../components/CourseIndex';

export const mapStateToProps = ({ auth, courses }) => {
  const { isFetching, receivedAt, ...courseList } = courses;

  return {
    isFetching,
    courses: courseList,
    user: auth.user.attributes,
  };
};

export const mapDispatchToProps = dispatch => ({
  fetchCourseIndex() {
    return dispatch(fetchCourseListIfNeeded());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseIndex);
