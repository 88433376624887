import { fetchCourseIfNeeded } from './course';
import UrlResolver from '../lib/UrlResolver';
import fetchContent from '../lib/fetchContent';

export const REQUEST_PAGE = 'REQUEST_PAGE';
export function requestPage(pageId) {
  return {
    type: REQUEST_PAGE,
    pageId,
  };
}

export const STORE_PAGE = 'STORE_PAGE';
export function storePage(pageId, content) {
  return {
    type: STORE_PAGE,
    pageId,
    content,
  };
}

function fetchPage(courseId, pageId) {
  return (dispatch, getState) => {
    dispatch(requestPage(pageId));

    const { pages, keys } = getState().courses[courseId];
    const page = pages[pageId];

    const url = new UrlResolver({ courseId, keys }).absoluteUrlFor(page.filename);
    return fetchContent(url).then(content => dispatch(storePage(pageId, content)));
  };
}

function shouldFetchPage({ pages }, pageId) {
  return !(pageId in pages);
}

export function fetchPageIfNeeded(courseId, pageId) {
  return (dispatch, getState) => {
    if (shouldFetchPage(getState(), pageId)) {
      return dispatch(fetchCourseIfNeeded(courseId)).then(
        () => dispatch(fetchPage(courseId, pageId)),
      );
    }

    return Promise.resolve();
  };
}
