import React from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../PageTitle';

import '../../styles/AccountsPage.scss';
import '../../styles/Button.scss';

const ExpiredCode = () => (
  <PageTitle title="Reset Password">
    <div style={{ maxWidth: '450px' }}>
      <h1 className="AccountsPage-Title">Sorry!</h1>
      <p className="AccountsPage-Description">
        It looks like the reset link used is expired. Click the button below to enter your email and
        request a new reset link:
      </p>
      <Link to="/login/forgot" className="Button" style={{ marginTop: '2rem' }}>
        Request New Code
      </Link>
    </div>
  </PageTitle>
);

export default ExpiredCode;
