import React from 'react';
import PageTitle from '../PageTitle';

import NewPasswordForm from './NewPasswordForm';

export default props => (
  <PageTitle title="New Password">
    <NewPasswordForm
      title="Finish Creating Account"
      description="You're almost there! Create a new password to continue."
      {...props}
    />
  </PageTitle>
);
