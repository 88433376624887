import { REQUEST_PAGE, STORE_PAGE } from '../actions/page';

export default function pages(state = {}, action) {
  switch (action.type) {
    case REQUEST_PAGE:
      return Object.assign({}, state, { [action.pageId]: null });
    case STORE_PAGE:
      return Object.assign({}, state, {
        [action.pageId]: action.content,
      });
    default:
      return state;
  }
}
