import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';

import CredentialsForm from '../../containers/CredentialsForm';
import CreatePassword from '../../containers/CreatePassword';
import ForgotPassword from '../../containers/ForgotPassword';
import ResetPassword from '../../containers/ResetPassword';
import ExpiredCode from './ExpiredCode';

import '../../styles/AccountsPage.scss';
import '../../styles/Header.scss';

class LoginLayout extends React.Component {
  componentDidMount() {
    const { restoreSession } = this.props;
    restoreSession();
  }

  render() {
    const { isLoggedIn, isRestoringUser, needsNewPassword, location, match } = this.props;

    if (isLoggedIn) {
      const { from } = location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }

    if (isRestoringUser) {
      return null;
    }

    return (
      <div className="AccountsPage">
        <div className="AccountsPage-Brand">
          <svg
            className="AccountsPage-Accent"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="100,85 100,75 0,30 0,100" />
          </svg>
          <img src="/assets/images/logo_white.png" alt="logo" className="logo" />
        </div>
        <main className="AccountsPage-Body Header-Space">
          <Switch>
            <Route path={`${match.url}/forgot`} component={ForgotPassword} />
            <Route path={`${match.url}/reset`} component={ResetPassword} />
            <Route path={`${match.url}/expired`} component={ExpiredCode} />
            {needsNewPassword && <CreatePassword />}
            <CredentialsForm />
          </Switch>
        </main>
        <header className="Header AccountsPage-Header">
          <img src="/assets/images/logo_white.png" alt="logo" className="logo" />
        </header>
      </div>
    );
  }
}

LoginLayout.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isRestoringUser: PropTypes.bool.isRequired,
  needsNewPassword: PropTypes.bool.isRequired,
  restoreSession: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default LoginLayout;
