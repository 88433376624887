import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import PageTitle from './PageTitle';

import Layout from './Layout';
import CourseItem from './CourseItem';

import '../styles/CourseIndex.scss';

class CourseIndex extends React.Component {
  constructor(props) {
    super(props);

    this.renderNav = this.renderNav.bind(this);
  }

  componentDidMount() {
    const { fetchCourseIndex } = this.props;
    fetchCourseIndex();
  }

  renderNav() {
    const { user } = this.props;

    return (
      <div className="CourseIndex-Sidebar">
        <img src="/assets/images/vmLogo_white.svg" alt="logo" className="CourseIndex-Logo" />
        <div style={{ margin: '2rem ' }}>
          <h2 className="CourseIndex-Greeting">{`Hello ${user.given_name},`}</h2>
          <p style={{ margin: 0 }}>{user.email}</p>
        </div>

        <div className="CourseIndex-Nav">
          <ol className="CourseNav">
            <li>
              <NavLink className="CourseNav-Header" to="/courses">
                My Courses
              </NavLink>
              <Link className="CourseNav-Header" to="/logout">
                Logout
              </Link>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  render() {
    const { isFetching, courses } = this.props;

    return (
      <PageTitle title="My Courses">
        <Layout nav={this.renderNav}>
          {isFetching ? (
            <p>Loading</p>
          ) : (
            <div className="CourseIndex-Body">
              <h2 className="CourseIndex-Title">My Courses</h2>

              <div className="CourseIndex-Courses">
                {Object.keys(courses)
                  .filter(id => !courses[id].hidden)
                  .sort((a, b) => courses[a].startedAt - courses[b].startedAt)
                  .map(courseId => (
                    <CourseItem {...courses[courseId]} key={courseId} />
                  ))}
              </div>
            </div>
          )}
        </Layout>
      </PageTitle>
    );
  }
}

CourseIndex.propTypes = {
  isFetching: PropTypes.bool,
  fetchCourseIndex: PropTypes.func.isRequired,
  courses: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    given_name: PropTypes.string,
    email: PropTypes.string,
  }),
};

CourseIndex.defaultProps = {
  isFetching: false,
  user: {
    given_name: '',
    email: '',
  },
};

export default CourseIndex;
