import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Menu } from 'react-feather';

import '../styles/Header.scss';
import '../styles/Layout.scss';

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavigating: false,
      bodyScroll: 0,
      navScroll: 0,
    };

    this.resetNav = this.resetNav.bind(this);
  }

  resetNav() {
    this.setState({ bodyScroll: 0 });
    this.toggleNav(false);
  }

  toggleNav(open) {
    const currentScroll =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.setState(
      state => {
        const navState = typeof open === 'undefined' ? !state.isNavigating : open;
        return {
          isNavigating: navState,
          [navState ? 'bodyScroll' : 'navScroll']: currentScroll,
        };
      },
      () => {
        const { isNavigating, bodyScroll, navScroll } = this.state;
        window.scrollTo(0, isNavigating ? navScroll : bodyScroll);
      },
    );
  }

  render() {
    const { children, footer, nav } = this.props;
    const { isNavigating, bodyScroll, navScroll } = this.state;

    return (
      <CSSTransition in={isNavigating} timeout={400} classNames="navigating">
        <div className="Layout">
          <header className="Header Layout-Header Layout-Translate">
            <button
              className="Layout-NavToggle"
              onClick={() => this.toggleNav()}
              type="button"
              aria-label="Toggle Navigation Drawer"
              aria-expanded={isNavigating}
              aria-controls="navbarDrawer"
            >
              <Menu />
            </button>
            <img src="/assets/images/logo_white.png" alt="logo" className="logo" />
          </header>

          <div
            className="Layout-Overlay Layout-Translate"
            onClick={() => this.toggleNav()}
            onKeyPress={({ which }) => which === 32 && this.toggleNav()}
            role="button"
            aria-label="Toggle Navigation Drawer"
            aria-expanded={isNavigating}
            aria-controls="navbarDrawer"
            tabIndex="0"
          />

          <main
            className="Layout-Body Layout-Translate Header-Pad"
            style={{ top: `${(isNavigating || 0) && -bodyScroll}px` }}
          >
            {children}
          </main>

          <footer className="Layout-Footer Layout-Translate">{footer && footer(this)}</footer>

          <nav
            className="Layout-Nav"
            style={{ top: `${(!isNavigating || 0) && -navScroll}px` }}
            aria-expanded={isNavigating}
            id="navbarDrawer"
          >
            {nav(this)}
          </nav>
        </div>
      </CSSTransition>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.func,
  nav: PropTypes.func.isRequired,
};

Layout.defaultProps = {
  children: null,
  footer: undefined,
};

export default Layout;
