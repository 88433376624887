import { connect } from 'react-redux';
import { fetchCourseIfNeeded } from '../actions/course';
import { fetchPageIfNeeded } from '../actions/page';
import Course from '../components/Course';

export const mapStateToProps = ({ courses }, { match }) => {
  const { courseId } = match.params;
  const course = courses[courseId] || {};

  return { ...course, courseId };
};

export const mapDispatchToProps = (dispatch, { match }) => ({
  fetchCourseOutline() {
    const { courseId } = match.params;
    return dispatch(fetchCourseIfNeeded(courseId));
  },
  fetchPageContent(pageId) {
    const { courseId } = match.params;
    return dispatch(fetchPageIfNeeded(courseId, pageId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Course);
