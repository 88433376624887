import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import UrlResolver from '../../lib/UrlResolver';
import ImageFacade from '../ImageFacade';
import LinkFacade from '../LinkFacade';

import '../../styles/Page.scss';

function fetchPage({ fetchPageContent, pageId }) {
  fetchPageContent(pageId);
}

class CourseContent extends React.Component {
  componentDidMount() {
    fetchPage(this.props);
  }

  componentDidUpdate({ fetchPageContent, pageId: oldPageId }) {
    const { pageId } = this.props;
    if (pageId !== oldPageId) {
      fetchPage({ fetchPageContent, pageId });
    }
  }

  render() {
    const { page, pageContent, urlResolver } = this.props;
    return pageContent ? (
      <section className="Page">
        {page.label && <span className="Page-Label">{`${page.type || ''} ${page.label}`}</span>}
        <Markdown
          options={{
            overrides: {
              img: {
                component: ImageFacade,
                props: {
                  urlResolver,
                },
              },
              a: {
                component: LinkFacade,
                props: {
                  urlResolver,
                },
              },
            },
          }}
        >
          {pageContent}
        </Markdown>
      </section>
    ) : (
      <p>Loading</p>
    );
  }
}

CourseContent.propTypes = {
  pageId: PropTypes.string.isRequired,
  page: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  fetchPageContent: PropTypes.func.isRequired,
  pageContent: PropTypes.string,
  urlResolver: PropTypes.instanceOf(UrlResolver).isRequired,
};

CourseContent.defaultProps = {
  pageContent: '',
};

export default CourseContent;
