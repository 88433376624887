import React from 'react';
import PropTypes from 'prop-types';

const Vimeo = ({ src }) => {
  const videoId = /\/\d+$/.exec(src);

  return (
    // eslint-disable-next-line
    <iframe
      src={`https://player.vimeo.com/video${videoId}`}
      width="640"
      height="360"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  );
};

Vimeo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Vimeo;
