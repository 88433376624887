import { connect } from 'react-redux';
import { restoreSession } from '../actions/auth';
import LoginComponent from '../components/Login';

export const mapStateToProps = ({ auth }) => ({
  isLoggedIn: !!auth.user && auth.user.challengeName !== 'NEW_PASSWORD_REQUIRED',
  needsNewPassword: !!auth.user && auth.user.challengeName === 'NEW_PASSWORD_REQUIRED',
  isRestoringUser: auth.isRestoring,
});

export const mapDispatchToProps = dispatch => ({
  restoreSession() {
    return dispatch(restoreSession());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginComponent);
