import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../Alert';

import '../../styles/AccountsPage.scss';
import '../../styles/Button.scss';

class NewPasswordForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      confirmPassword: '',
    };
  }

  handleClick(e) {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { newPassword, confirmPassword } = this.state;

    onSubmit({ newPassword, confirmPassword });
  }

  render() {
    const { title, description, error, disabled } = this.props;
    const { newPassword, confirmPassword } = this.state;

    return (
      <form noValidate autoComplete="off">
        <h1 className="AccountsPage-Title">{title}</h1>
        <p className="AccountsPage-Description">{description}</p>
        {error && <Alert message={error} level="error" />}
        <input
          placeholder="New Password"
          name="password"
          type="password"
          disabled={disabled}
          value={newPassword}
          onChange={e => this.setState({ newPassword: e.target.value })}
        />

        <input
          placeholder="Confirm New Password"
          name="confirmPassword"
          type="password"
          disabled={disabled}
          value={confirmPassword}
          onChange={e => this.setState({ confirmPassword: e.target.value })}
        />
        <button
          className="Button"
          onClick={this.handleClick.bind(this)}
          type="submit"
          disabled={disabled}
        >
          Save Password
        </button>
      </form>
    );
  }
}

NewPasswordForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

NewPasswordForm.defaultProps = {
  error: '',
  disabled: false,
};

export default NewPasswordForm;
