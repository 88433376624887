import Auth from '@aws-amplify/auth';

import messages from '../lib/en';

const COGNITO_ERRORS = {
  InvalidParameterException: 'passwordComplexity',
  InvalidPasswordException: 'passwordComplexity',
  CodeMismatchException: 'invalidCode',
  ExpiredCodeException: 'invalidCode',
};

const CODE_TEST_PASSWORD = '123456';

export const RESTORE_USER = 'RESTORE_USER';
export function restoreUser() {
  return {
    type: RESTORE_USER,
  };
}

export const REQUEST_USER = 'REQUEST_USER';
export function requestUser() {
  return {
    type: REQUEST_USER,
  };
}

export const STORE_USER = 'STORE_USER';
export function storeUser(user) {
  return {
    type: STORE_USER,
    user,
  };
}

export const DELETE_USER = 'DELETE_USER';
export function deleteUser() {
  return {
    type: DELETE_USER,
  };
}

export const LOGIN_ERROR = 'LOGIN_ERROR';
export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}

export const STORE_RESET_CREDS = 'STORE_RESET_CREDS';
export function storeResetCreds(resetCredentials) {
  return {
    type: STORE_RESET_CREDS,
    resetCredentials,
  };
}

export function restoreSession() {
  return dispatch => {
    dispatch(restoreUser());

    return Auth.currentAuthenticatedUser().then(
      user => dispatch(storeUser(user)),
      () => dispatch(deleteUser()),
    );
  };
}

export function loginUser(username, password) {
  return dispatch => {
    dispatch(requestUser());

    if (!username || !password) {
      return Promise.resolve(dispatch(loginError(messages.login.blank)));
    }

    return Auth.signIn(username, password).then(
      user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(storeUser(user)); // Can't restore until finished creating
        } else {
          dispatch(restoreSession()); // Gets all attributes
        }
      },
      () => dispatch(loginError(messages.login.invalid)),
    );
  };
}

export function setNewPassword(password, confirmPassword) {
  return (dispatch, getState) => {
    dispatch(requestUser());

    if (!password) {
      return Promise.resolve(dispatch(loginError(messages.login.passwordBlank)));
    }

    if (!password || password !== confirmPassword) {
      return Promise.resolve(dispatch(loginError(messages.login.passwordsNoMatch)));
    }

    const { auth } = getState();

    return Auth.completeNewPassword(auth.user, password).then(
      () => dispatch(restoreSession()),
      () => dispatch(loginError(messages.login.passwordComplexity)),
    );
  };
}

export function forgotPassword(username) {
  return dispatch => {
    dispatch(requestUser());

    if (!username) {
      return Promise.reject(dispatch(loginError(messages.login.emailBlank)));
    }

    return Auth.forgotPassword(username).then(
      () => dispatch(deleteUser()),
      () => dispatch(deleteUser()),
    );
  };
}

export function resetPassword(newPassword, confirmPassword) {
  return (dispatch, getState) => {
    dispatch(requestUser());

    if (!newPassword) {
      return Promise.resolve(dispatch(loginError(messages.login.passwordBlank)));
    }

    if (newPassword !== confirmPassword) {
      return Promise.resolve(dispatch(loginError(messages.login.passwordsNoMatch)));
    }

    const { resetCredentials } = getState().auth;
    if (!resetCredentials) {
      return Promise.resolve(dispatch(loginError(messages.login.invalidCode)));
    }

    const { username, code } = resetCredentials;
    return Auth.forgotPasswordSubmit(username, code, newPassword).then(
      () => dispatch(loginUser(username, newPassword)),
      ({ code: errorCode }) => {
        dispatch(loginError(messages.login[COGNITO_ERRORS[errorCode]]));
      },
    );
  };
}

export function validateResetCredentials(username, code) {
  return dispatch => {
    if (!username || !code) {
      return Promise.resolve(dispatch(storeResetCreds(null)));
    }

    return Auth.forgotPasswordSubmit(username, code, CODE_TEST_PASSWORD).catch(
      ({ code: errorCode }) =>
        ['ExpiredCodeException', 'CodeMismatchException'].includes(errorCode)
          ? dispatch(storeResetCreds(null))
          : dispatch(storeResetCreds({ username, code })),
    );
  };
}

export function logoutUser() {
  return dispatch => {
    dispatch(requestUser());

    return Auth.signOut({ global: true }).then(
      () => dispatch(deleteUser()),
      () => dispatch(deleteUser()),
    );
  };
}
