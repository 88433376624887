import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { logoutUser } from '../actions/auth';

// TODO: Share this logic amongst other containers?
export const mapStateToProps = ({ auth }) => ({
  isLoggedIn: !!auth.user && auth.user.challengeName !== 'NEW_PASSWORD_REQUIRED',
});

export const mapDispatchToProps = dispatch => ({
  signOutUser: () => dispatch(logoutUser()),
});

export class LogoutComponent extends React.Component {
  componentDidMount() {
    const { signOutUser } = this.props;
    signOutUser();
  }

  render() {
    const { isLoggedIn } = this.props;
    return isLoggedIn ? null : <Redirect to="/" />;
  }
}

LogoutComponent.propTypes = {
  signOutUser: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutComponent);
