import { connect } from 'react-redux';
import { loginUser, loginError } from '../actions/auth';
import CredentialsForm from '../components/Login/CredentialsForm';

export const mapStateToProps = ({ auth }) => ({
  isLoggingIn: auth.isFetching,
  error: auth.error,
});

export const mapDispatchToProps = dispatch => ({
  logIn(email, password) {
    return dispatch(loginUser(email, password));
  },
  resetError() {
    return dispatch(loginError(null));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CredentialsForm);
