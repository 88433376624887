import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../styles/Alert.scss';

const classNameMap = {
  error: 'Alert-Error',
  success: 'Alert-Success',
};

const Alert = ({ message, level }) => {
  const classes = classNames('Alert', classNameMap[level]);
  return (
    <p className={classes} role="alert">
      {message}
    </p>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  level: PropTypes.oneOf(Object.keys(classNameMap)).isRequired,
};

export default Alert;
