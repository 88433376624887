import { REQUEST_COURSE_LIST, STORE_COURSE_LIST } from '../actions/courseList';
import { REQUEST_COURSE, STORE_COURSE } from '../actions/course';

const initialState = {
  isFetching: false,
  receivedAt: null,
};

export default function courses(state = initialState, action) {
  switch (action.type) {
    case REQUEST_COURSE_LIST:
      return Object.assign({}, state, { isFetching: true });
    case STORE_COURSE_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        receivedAt: action.receivedAt,
        ...action.courses,
      });
    case REQUEST_COURSE:
      return Object.assign({}, state, {
        [action.courseId]: {
          ...state[action.courseId],
          isFetching: action.coursePromise,
        },
      });
    case STORE_COURSE:
      return Object.assign({}, state, {
        [action.courseId]: {
          ...state[action.courseId],
          ...action.course,
          isFetching: false,
        },
      });
    default:
      return state;
  }
}
