import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import PageTitle from '../PageTitle';

import NewPasswordForm from './NewPasswordForm';

class ResetPassword extends React.PureComponent {
  componentDidMount() {
    const { isLoggedIn, validateCode } = this.props;

    if (!isLoggedIn) {
      validateCode();
    }
  }

  render() {
    const {
      isLoggedIn,
      isResetting,
      error,
      isValidating,
      resetCredentials,
      submitForgotPassword,
    } = this.props;

    if (isLoggedIn) {
      return <Redirect to="/" />;
    }

    if (!isValidating && !resetCredentials) {
      return <Redirect to="/login/expired" />;
    }

    return isValidating ? null : (
      <PageTitle title="Reset Password">
        <NewPasswordForm
          title="Reset Your Password"
          description="You're almost there! Choose a new password."
          disabled={isResetting}
          error={error}
          onSubmit={submitForgotPassword}
        />
      </PageTitle>
    );
  }
}

ResetPassword.propTypes = {
  submitForgotPassword: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isResetting: PropTypes.bool,
  isValidating: PropTypes.bool,
  validateCode: PropTypes.func.isRequired,
  resetCredentials: PropTypes.shape({
    username: PropTypes.string,
    code: PropTypes.string,
  }),
};

ResetPassword.defaultProps = {
  error: '',
  isResetting: false,
  isValidating: true,
  resetCredentials: null,
};

export default ResetPassword;
