import Auth from '@aws-amplify/auth';
import { logoutUser } from './auth';
import fetch from '../lib/api';

export const REQUEST_COURSE = 'REQUEST_COURSE';
export function requestCourse(courseId, coursePromise) {
  return {
    type: REQUEST_COURSE,
    courseId,
    coursePromise,
  };
}

export const STORE_COURSE = 'STORE_COURSE';
export function storeCourse(courseId, course) {
  return {
    type: STORE_COURSE,
    courseId,
    course,
  };
}

function fetchCourse(courseId) {
  return dispatch => {
    const coursePromise = new Promise((resolve, reject) => {
      Auth.currentSession().then(
        ({ idToken }) => {
          const { jwtToken } = idToken;

          Promise.all([
            fetch(`/courses/${courseId}`, { token: jwtToken }).catch(() => {}),
            fetch(`/${courseId}/index.json`, {
              apiBase: process.env.REACT_APP_PUBLIC_CDN,
            }).catch(() => {}),
          ]).then(([privateCourse, publicCourse]) =>
            resolve(dispatch(storeCourse(courseId, privateCourse || publicCourse))),
          );
        },
        () => {
          // Not logged in
          dispatch(logoutUser());
          fetch(`/${courseId}/index.json`, {
            apiBase: process.env.REACT_APP_PUBLIC_CDN,
          })
            .then(course => resolve(dispatch(storeCourse(courseId, course))))
            .catch(() => reject(new Error('COURSE_NOT_FOUND')));
        },
      );
    });

    dispatch(requestCourse(courseId, coursePromise));
    return coursePromise;
  };
}

const isCurrentlyFetching = course => !!course && course.isFetching;
const hasCourseKeys = course => !!course && course.keys && course.keys.Expires * 1000 > Date.now();

function shouldFetchCourse({ courses }, courseId) {
  const course = courses[courseId];
  return !hasCourseKeys(course);
}

export function fetchCourseIfNeeded(courseId) {
  return (dispatch, getState) => {
    const { courses } = getState();
    const course = courses[courseId];

    if (isCurrentlyFetching(course)) {
      return course.isFetching;
    }

    if (shouldFetchCourse({ courses }, courseId)) {
      return dispatch(fetchCourse(courseId));
    }
    return Promise.resolve();
  };
}
