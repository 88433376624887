import React from 'react';
import PropTypes from 'prop-types';

const AccessibleLink = ({ accessible, type: Type, ...props }) =>
  accessible ? <Type {...props} /> : <a {...props} disabled />; // eslint-disable-line jsx-a11y/anchor-has-content

AccessibleLink.propTypes = {
  accessible: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

AccessibleLink.defaultProps = {
  accessible: true,
};

export default AccessibleLink;
