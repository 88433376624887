import { connect } from 'react-redux';
import { forgotPassword, loginError } from '../actions/auth';
import ForgotPassword from '../components/Login/ForgotPassword';

export const mapStateToProps = ({ auth }) => ({
  isSendingEmail: auth.isFetching,
  error: auth.error,
});

export const mapDispatchToProps = dispatch => ({
  requestForgotCode(email) {
    return dispatch(forgotPassword(email));
  },
  resetError() {
    return dispatch(loginError(null));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);
