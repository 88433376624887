import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Download } from 'react-feather';

import UrlResolver from '../lib/UrlResolver';

import '../styles/Button.scss';

const isFullUrl = href => /^(http|https):\/\//.test(href);
const isFile = href => /.+\.\w+$/.test(href);

const LinkFacade = ({ title, href, children, urlResolver }) => {
  if (isFullUrl(href)) {
    return (
      <a href={href} title={title}>
        {children}
      </a>
    );
  }

  if (isFile(href)) {
    return (
      <a
        href={urlResolver.absoluteUrlFor(href)}
        title={title}
        target="_blank"
        download
        className="Button Button--flex Button--smaller"
        rel="noopener noreferrer"
      >
        <Download className="Button-Icon" />
        {children}
      </a>
    );
  }

  return (
    <Link to={href} title={title}>
      {children}
    </Link>
  );
};

LinkFacade.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  urlResolver: PropTypes.instanceOf(UrlResolver).isRequired,
};

LinkFacade.defaultProps = {
  title: '',
  children: '',
};

export default LinkFacade;
