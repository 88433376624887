export default {
  login: {
    blank: 'Oops, you need both your email and password to log in.',
    invalid: "Hmmm, looks like you've entered an invalid email or password.",
    emailBlank: 'Please provide your email address.',
    invalidCode:
      "Sorry, I couldn't save your password. Either the reset link is invalid or expired.  Try requesting a new reset email.",
    passwordBlank: 'Oops...you have to enter a new password.',
    passwordsNoMatch: "Oops...the passwords don't match. Try typing them again.",
    passwordComplexity:
      "Sorry, I couldn't save that password.  It must be at least 8 characters with uppercase and lowercase letters, and numbers.",
  },
};
